import ReactGA4 from 'react-ga4';

let appEl;

export function initGA(appElement) {
    appEl = appElement;

    const required = {
        tocsApp: false,
        productVariantCode: false,
        i18n_primaryLanguage: false,
    };

    let initialized = false;
    window.dataLayer = window.dataLayer || [];

    const init = () => {
        window.initGTM?.();

        if (
            typeof window.config.GOOGLE_ANALYTICS_4 === 'string' &&
            window.config.GOOGLE_ANALYTICS_4 !== 'false' &&
            window.config.GOOGLE_ANALYTICS_4 !== ''
        ) {
            ReactGA4.initialize(window.config.GOOGLE_ANALYTICS_4, {
                gtagOptions: {
                    linker: {
                        domains: ['app.twigscience.com', 'login.twigscience.com'],
                    },
                },
            });
        } else {
            ReactGA4.initialize('G-OOO0OOO0OO');
        }

        initialized = true;
    };

    const gaSetHandler = event => {
        if (!initialized) {
            window.dataLayer.push(event.detail);

            Object.keys(event.detail).forEach(key => {
                if (Object.hasOwn(required, key)) {
                    required[key] = true;
                }
            });

            if (Object.values(required).every(Boolean)) {
                init();
            }
        } else {
            ReactGA4.set(event.detail);
        }
    };

    appEl.addEventListener('gaset', gaSetHandler);
}

export function gaSet(data) {
    if (!appEl) {
        throw new Error('initGA must be called before gaSet');
    }

    const event = new CustomEvent('gaset', { detail: data });
    appEl.dispatchEvent(event);
}
