import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';
import { connect } from 'react-redux';
import AsyncComponentError from '../AsyncComponentError';

const Navbar = props => (
    <AsyncComponent appName="ms-navbar-mfe" componentName="Navbar" errorComponent={AsyncComponentError} {...props} />
);

const mapStateToProps = state => ({ userInfo: state.userInfo, hostApp: 'MS' });
export default withSignoutRedirect(() => {})(connect(mapStateToProps)(Navbar));
