import React from 'react';
import { Route } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import AsyncComponentError from '../../components/AsyncComponentError';

const CreditsPage = props => (
    <AsyncComponent
        appName="ts-credits-mfe"
        componentName="CreditsPage"
        errorComponent={AsyncComponentError}
        {...props}
    />
);

const routes = [
    <Route
        path="/credits"
        key="credits"
        component={() => (
            <WithAuthentication>
                <CreditsPage />
            </WithAuthentication>
        )}
    />,
];

export default routes;
