import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '@twigeducation/ts-fe-components';
import NotSubscribedError from '../../pages/NotSubscribedError';

const WithProductSubscriptions = Component => {
    const withProductSubscriptionsComponent = props => {
        const subscriptions = useSelector(state => state.subscriptions);
        const isSubscriptionDataMissing = !Object.keys(subscriptions).length;
        const isProductMissing = !subscriptions?.product?.tocsApp || !subscriptions?.product?.productVariantCode;

        if (isSubscriptionDataMissing) {
            return <Loading isLoadingPage />;
        }

        if (isProductMissing) {
            return <NotSubscribedError />;
        }

        return <Component {...props} />;
    };

    return withProductSubscriptionsComponent;
};

export default WithProductSubscriptions;
