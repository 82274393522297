import React from 'react';
import PropTypes from 'prop-types';
import { AccessErrorPage as AccessErrorPageExternal, PageHeadAttributes } from '@twigeducation/allsorts';
import useTranslation from '../../hooks/useTranslation';
import StyledAccessErrorPageExternalForLti from './AccessErrorPage.styled';
import useIsLtiUser from '../../components/Subscriptions/useIsLtiUser';

const AccessErrorPage = ({ link }) => {
    const { t } = useTranslation();
    const isLtiUser = useIsLtiUser();
    return (
        <>
            <PageHeadAttributes title={t('access_error_page.page_title', 'Not Authorised')} separator="pipe" />
            {!isLtiUser ? (
                <AccessErrorPageExternal
                    link={link}
                    buttonLabel={t('access_error_page.button_label', 'Go to my Dashboard')}
                    message={t(
                        'access_error_page.message',
                        `You don't have access to this page. If you have multiple accounts, 
please check you are logged in with the correct account.`,
                    )}
                    title={t('access_error_page.title', 'Uh oh, you look a bit lost!')}
                />
            ) : (
                <StyledAccessErrorPageExternalForLti>
                    <AccessErrorPageExternal
                        link={link}
                        buttonLabel={t('access_error_page.button_label', 'Go to my Dashboard')}
                        message={t(
                            'access_error_page.message',
                            `You don't have access to this page. If you have multiple accounts, 
                please check you are logged in with the correct account.`,
                        )}
                        title={t('access_error_page.title', 'Uh oh, you look a bit lost!')}
                    />
                </StyledAccessErrorPageExternalForLti>
            )}
        </>
    );
};

AccessErrorPage.propTypes = {
    link: PropTypes.string.isRequired,
};

export default AccessErrorPage;
