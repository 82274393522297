import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import {
    elementScrollIntoViewPolyfill,
    elementScrollToPolyfill,
    windowScrollToPolyfill,
} from 'seamless-scroll-polyfill';
import { AuthenticationProvider } from '@twigeducation/oidc-client-react';
import { Provider, connect } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { LoadManifests } from '@twigeducation/async-component';
import styled, { ThemeProvider } from 'styled-components';
import { middleSchoolTheme, GlobalStyle, PageError } from '@twigeducation/ts-fe-components';
import { UnleashConsumer } from '@twigeducation/unleash-client-react';
import SentryWrapper from './Sentry/Sentry';
import ErrorBoundary from './components/ErrorBoundary';
import { setFeatureFlags } from './store/actions/featureFlags';
import CustomUnleash from './components/Unleash';
import App from './App';
import history from './history';
import Routes from './Routes';
import { UserManagerContainer } from './Authentication/userManager';
import store from './store/store';
import Subscriptions from './components/Subscriptions/Subscriptions';
import client from './apolloClient';
import './microExport';
import './i18n';
import GlobalFonts from './GlobalFonts';
import 'react-toastify/dist/ReactToastify.css';
import I18n from './components/I18n';
import DevTools from './components/DevTools';
import GlobalReadSpeakerStyles from './components/ReadSpeaker/ReadSpeaker.styles';
import { initGA } from './helpers/ga';

elementScrollIntoViewPolyfill();
elementScrollToPolyfill();
windowScrollToPolyfill();

window.app = new App();

const ContentComponent = connect(null, { setFeatureFlags })(props => (
    <CustomUnleash>
        <UnleashConsumer>
            {featureFlags => {
                props.setFeatureFlags(featureFlags);
                return (
                    <Suspense fallback={<p>Loading...</p>}>
                        <ApolloProvider client={client}>
                            <Subscriptions store={store}>
                                <LoadManifests manifestServiceUrl={window.config.MANIFEST_SERVICE_URL}>
                                    <I18n>
                                        <DevTools />
                                        <Router history={history}>
                                            <Routes />
                                        </Router>
                                    </I18n>
                                </LoadManifests>
                            </Subscriptions>
                        </ApolloProvider>
                    </Suspense>
                );
            }}
        </UnleashConsumer>
    </CustomUnleash>
));

const StyledPageError = styled(PageError)`
    height: 100vh;
    justify-content: center;
`;

const RootLevelError = () => (
    <StyledPageError
        title="Oops! Something went wrong!"
        body="We're sorry about that, we'll try to stop it from happening again"
        buttonConfig={{
            label: 'Go to my Dashboard',
            href: '/',
            primary: true,
        }}
    />
);

const MainComponent = ErrorBoundary(
    'Top level page error',
    [],
    RootLevelError,
)(() => (
    <ApolloProvider client={client}>
        <Provider store={store}>
            <SentryWrapper>
                <AuthenticationProvider userManager={UserManagerContainer.getInstance()} store={store}>
                    <ContentComponent />
                </AuthenticationProvider>
            </SentryWrapper>
        </Provider>
    </ApolloProvider>
));

const appElement = document.getElementById('app');
initGA(appElement);

ReactDOM.render(
    <ThemeProvider theme={middleSchoolTheme}>
        <GlobalStyle />
        <GlobalFonts />
        <GlobalReadSpeakerStyles />
        <MainComponent />
    </ThemeProvider>,
    appElement,
);
