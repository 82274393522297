import React from 'react';
import { connect, useSelector } from 'react-redux';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import { Loading } from '@twigeducation/ts-fe-components';
import { Route } from 'react-router-dom';
import { string } from 'prop-types';
import Translation from '../../components/Translation';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import AsyncComponentError from '../../components/AsyncComponentError';

const AssetMissingOrError = () => (
    <Translation translationKey="errors.asset_modal_missing_error" defaultValue="This asset could not be displayed." />
);

const AssetLoading = () => <Loading isLoadingPage />;

const VideoTranscriptProvider = withProductSubscriptions(({ defaultLanguage, language, resourceId }) => {
    const subscriptions = useSelector(state => state.subscriptions);
    const { tocsApp } = subscriptions.product;

    return (
        <AsyncComponent
            appName="twig-video-mfe"
            applicationId={tocsApp}
            componentName="TranscriptProvider"
            errorComponent={AsyncComponentError}
            resourceId={resourceId}
            language={language || defaultLanguage}
            Loading={AssetLoading}
            NotFound={AssetMissingOrError}
            PageError={AssetMissingOrError}
        />
    );
});

VideoTranscriptProvider.defaultProps = {
    language: 'en-US',
};

VideoTranscriptProvider.propTypes = {
    defaultLanguage: string.isRequired,
    language: string,
    resourceId: string.isRequired,
};

const EnhancedVideoTranscriptProvider = connect(({ application }) => ({
    defaultLanguage: application.defaultLanguage,
}))(VideoTranscriptProvider);

const allowedRoles = ['STUDENT', 'TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];

const routes = [
    <Route
        path="/asset/video/:slug/:resourceId/transcript/:language?"
        component={({ match }) => (
            <WithAuthentication roles={allowedRoles}>
                <EnhancedVideoTranscriptProvider
                    Layout={Layout}
                    resourceId={atob(match.params.resourceId)}
                    language={match.params.language}
                />
            </WithAuthentication>
        )}
        key="video-transcript"
    />,
];

export default routes;
