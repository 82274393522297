import styled, { createGlobalStyle } from 'styled-components';

/* stylelint-disable selector-max-id, font-family-no-missing-generic-family-keyword */

export const ReadSpeakerContainer = styled.div``;

const GlobalReadSpeakerStyles = createGlobalStyle`

    #rs-dialog-header {
        font-family: ${props => props.theme?.primaryFont} !important;
        color: ${props => props.theme?.secondaryColor} !important;
        text-shadow: none;
    }

    #rs-controlpanel .rsicn-logo::after,
    #rs-controlpanel .rsicn-logo::before {
        top: 4px;
    }

    span.rsicn.rsicn-logo::after,
    span.rsicn.rsicn-logo::before  {
        color: ${props => props.theme?.primaryColor} !important;
    }

    #rs-dialog-contents {
        background: #fff !important;
        font-family: ${props => props.theme?.primaryFont} !important;
        color: ${props => props.theme?.primaryColor} !important;
        border: 1px solid #ebebeb !important;
        border-radius: 4px !important;
    }
    .rsicn {
        color: ${props => props.theme?.primaryColor} !important;
    }

    #rs-dialog #rs-dialog-main header>ul [role=button]:focus:not(.rs-dialog-resize),
    #rs-dialog #rs-dialog-main header>ul li:focus:not(.rs-dialog-resize) {
        background-color: #fff !important;
    }

    .rspopup {
        background: #fff !important;
        font-family: ${props => props.theme?.primaryFont} !important;
        color: ${props => props.theme?.primaryColor} !important;
        border: 1px solid #ebebeb !important;
        border-radius: 4px !important;
        padding: 5px !important;
    }

    .rspopup_dictionary .rspopup_btn {
        border-top: 1px solid ${props => props.theme?.primaryColor} !important;
        padding: 5px;
    }

    .rspopup .rspopup_btn {
        padding-top: 3px;
    }

    .rspopup .rspopup_btn.rspopup_play {
        border-top: none !important;
    }

    .rspopup_play.rspopup_btn::after,
    .rspopup_play.rspopup_btn::before {
        color: ${props => props.theme?.primaryColor} !important;
    }

    #rs-controlpanel,
    #rs-controlpanel .rsicn-rslogo-2019 {
        background: #fff !important;
        font-family: ${props => props.theme?.primaryFont} !important;
        color: ${props => props.theme?.primaryColor} !important;
        border: 1px solid #ebebeb !important;
        border-radius: 8px !important;
        padding: 5px !important;
        box-shadow: none !important;
        button > {
            &::before {
                color: ${props => props.theme?.primaryColor} !important;
            }
        }
    }

    #rs-controlpanel button.rs-controlpanel-btn {
        font-size: 0.8rem !important;
    }

    #rs-controlpanel button {
        border: 1px solid #ebebeb !important;
        border-radius: 8px !important;
        padding: 2px !important;
        font-size: 0.8rem !important;
        margin-top: 3px !important;
    }

    #rs-controlpanel button:focus {
        background: #cfd7df !important;
    }

    #rs-controlpanel button:focus.rs-controlpanel-scroll-toggler span {
        color: #000 !important;
    }

    .rsbtn .rsbtn_exp .rsbtn_exp_inner {
        border: none !important;
        background: none !important;
        min-width: 300px !important;
        padding-right: 10px !important;
    }

    .rsbtn .rs-player-loadindicator {
        top: -14px !important;
        background: #ebebeb !important;
    }

    .rsbtn .rs-player-loadindicator .rsbtn-progress {
        background: ${props => props.theme?.primaryColor} !important;
    }
    
    .rsbtn {
        white-space: nowrap !important;
        margin-bottom: 0 !important;
        background-color: #fff !important;
        border: 1px solid #ebebeb !important;
        border-radius: 8px !important;
        padding: 5px 2px 15px 12px !important;
        min-width: 140px !important;
        display: inline-block !important;
    }

    .rsbtn .rsbtn_play {
        border: none !important;
        background: #fff !important;
        margin: 5px 0 0 10px !important;
        &:hover,
        &:focus {
            background: #fff !important;
        }
    }

    .rs-controlpanel-scroll-label {
        font-family: ${props => props.theme?.primaryFont} !important;
        font-size: 1rem !important;
    }

    .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_player_item {
        background: #fff !important;
        border: 1px solid #ebebeb !important;
        border-radius: 8px !important;
        padding: 3px 0 !important;
        font-size: 1rem !important;
        margin: 2px !important;
        &:hover,
        &:focus,
        &:active {
            background: #cfd7df !important;
        }
    }

    .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_dropdown .rs-slider-label {
        color: ${props => props.theme?.primaryColor} !important;
        background: #fff !important;
    }

    .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_dropdown {
        border: 1px solid #ebebeb;
    }

    .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_stop span.rsicn,
    .rsbtn .rsbtn_play .rsbtn_right.rsimg,
    .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_closer:hover span.rsicn {
        color: ${props => props.theme?.primaryColor} !important;
    }

    .rsbtn_play:focus .rsbtn_left .rsbtn_text,
    .rsbtn .rsbtn_play .rsbtn_right:before {
        color: ${props => props.theme?.primaryColor} !important;
    }

    .rsbtn span {
        color: ${props => props.theme?.primaryColor} !important;
    }

    .rs-controlpanel-scroll-label,
    .rs-controlpanel-scroll-state {
        font-family: ${props => props.theme?.primaryFont} !important;
        font-size: 1rem !important;
        color: #000 !important;
        font-weight: normal !important;
    }

    .rsbtn.rsexpanded .rsbtn_exp {
        display: flex !important;
    }

    .translate-rspopup {
        transition: 0.3s !important;
        transform: translateY(-150px) !important;
    }

    .rsbtn .rsbtn_play .rsbtn_left .rsbtn_text {
        font-size: 18px !important;
        margin-left: 20px !important;
        top: 5px !important;
        font-family: ${props => props.theme?.primaryFont};
        color: ${props => props.theme?.primaryColor} !important;
        font-weight: 400 !important;
        padding-left: 10px !important;
        &::before {
            line-height: 1 !important;
            left: -26px !important;
            font-family: hl-icons !important;
            color: ${props => props.theme?.primaryColor} !important;
        }
        &::after {
            line-height: 1 !important;
            left: -26px !important;
            color: ${props => props.theme?.primaryColor} !important;
        }
        &:hover {
            filter: brightness(70%) !important;
        }
    }

    .rsbtn.mega_toggle .rsbtn_left {
        margin-left: 0 !important;
    }

    .rsbtn_tooltoggle {
        visibility: hidden !important;
    }

    .hide-rspopup{
        display: none !important;
    }
`;

export default GlobalReadSpeakerStyles;
