import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import { FeatureFlag } from '@twigeducation/unleash-client-react';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import digitalGlossaryUrl from './urls';
import ReadSpeaker from '../../components/ReadSpeaker';
import NotFound from '../NotFound';
import AsyncComponentError from '../../components/AsyncComponentError';

const DigitalGlossary = withProductSubscriptions(props => (
    <FeatureFlag
        key="digital-glossary"
        flagKey="digital-glossary"
        renderFeatureCallback={() => (
            <AsyncComponent
                appName="ms-digital-glossary-mfe"
                componentName="DigitalGlossary"
                errorComponent={AsyncComponentError}
                {...props}
            />
        )}
        renderDefaultCallback={() => <NotFound />}
    />
));

const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];
const routes = [
    <Route
        path={`${digitalGlossaryUrl}*`}
        render={() => (
            <WithAuthentication roles={allowedRoles}>
                <DigitalGlossary Layout={Layout} ReadSpeaker={ReadSpeaker} />
            </WithAuthentication>
        )}
        key="digital-glossary"
    />,
];
export default routes;
