import { gql } from '@apollo/client';

const getUserPreferencesByUserIdQuery = gql`
    query getUserPreferencesByUserIdQuery($userId: String!) {
        getUserPreferencesByUserId(userId: $userId) {
            id
            settings
        }
    }
`;

export default getUserPreferencesByUserIdQuery;
