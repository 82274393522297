import React from 'react';
import { Route } from 'react-router-dom';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import AccessErrorPage from '../AccessErrorPage';
import AssetPageRedirect from '../AssetPage';

const routes = [
    <Route
        path="/asset/:type/:title/:versionId"
        exact
        component={({ match }) => (
            <WithAuthentication
                roles={['STUDENT', 'TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN']}
                RoleErrorComponent={() => <AccessErrorPage link="/student-dashboard" />}
            >
                <AssetPageRedirect versionId={match.params.versionId} />
            </WithAuthentication>
        )}
        key="asset-page"
    />,
];

export default routes;
