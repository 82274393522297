import styled from 'styled-components';
import { PageError as StyledPageError } from '@twigeducation/ts-fe-components';

export default styled(StyledPageError)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
