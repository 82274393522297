/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Button } from '@twigeducation/ts-fe-components';

export const StyledSkipToContent = styled(Button)`
    position: absolute;
    top: -10rem;
    left: 6.5rem;
    z-index: 1100;

    &:focus {
        top: 0.5rem;
    }
`;
