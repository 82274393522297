import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { useSelector } from 'react-redux';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import AsyncComponentError from '../../components/AsyncComponentError';

const AssetPage = withProductSubscriptions(props => {
    const subscriptions = useSelector(state => state.subscriptions);
    const { tocsApp } = subscriptions.product;
    const { versionId } = useParams();

    return (
        <AsyncComponent
            appName="ms-asset-preview-mfe"
            componentName="AssetPage"
            errorComponent={AsyncComponentError}
            versionId={versionId}
            applicationId={tocsApp}
            {...props}
        />
    );
});

AssetPage.propTypes = {
    versionId: PropTypes.string.isRequired,
};

export default AssetPage;
