import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import AccessErrorPage from '../AccessErrorPage';
import Layout from '../../layout';
import ResponsiveBreadcrumb from '../../components/ResponsiveBreadcrumb';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import AsyncComponentError from '../../components/AsyncComponentError';

const TeacherSession = props => (
    <AsyncComponent
        appName="ms-teacher-session-mfe"
        componentName="TeacherSession"
        errorComponent={AsyncComponentError}
        unleash={{
            url: window.config.UNLEASH_URL,
            clientId: window.config.UNLEASH_CLIENT_ID,
        }}
        {...props}
    />
);

const TeacherSessionPage = withProductSubscriptions(() => (
    <>
        <ResponsiveBreadcrumb />
        <TeacherSession Layout={Layout} />
    </>
));

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];
const routes = [
    <Route
        path="/teacher-session/*"
        component={() => (
            <WithAuthentication
                roles={allowedRoles}
                RoleErrorComponent={() => <AccessErrorPage link="/teacher-dashboard" />}
            >
                <TeacherSessionPage />
            </WithAuthentication>
        )}
        key="teacher-session"
    />,
];

export default routes;
