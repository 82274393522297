import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Translation from '../Translation';

export const getReadSpeakerLanguage = lang => (lang === 'es-US' ? 'es_es' : 'en_us');

const ReadSpeaker = ({ elementId, sessionId }) => {
    const { READSPEAKER_BUTTON_URL, READSPEAKER_CUSTOMER_ID, READSPEAKER_VOICE, READSPEAKER_SPANISH_VOICE } =
        window.config;

    if (!(READSPEAKER_BUTTON_URL && READSPEAKER_CUSTOMER_ID && READSPEAKER_VOICE && READSPEAKER_SPANISH_VOICE)) {
        return null;
    }

    const primaryLanguage = sessionId && sessionId.substring(2, 4) === 'ES' ? 'es-US' : 'en-US';

    useEffect(() => {
        const { pl, ui, init, loadCore } = window.ReadSpeaker || {};

        const killPlayer = () => {
            if (pl) pl.stop();
            if (ui) {
                const player = ui.getActivePlayer();
                if (player) player.close();
            }
        };

        killPlayer();

        if (loadCore) {
            loadCore();
        }

        if (init) {
            init();
            if (ui) ui.addClickEvents();
        }

        return killPlayer;
    }, []);

    React.useEffect(() => {
        if (window.ReadSpeaker && window.ReadSpeaker.Common) {
            window.ReadSpeaker.Common.addEvent('onUIBeforePlay', () => {
                window.$rs.addClass('span[data-at=selector-multiple_choice_option_answer]', 'rs_skip_always');
                window.$rs.addClass('button[data-at=teacher-feedback-launch]', 'rs_skip_always');
                window.$rs.addClass('div[class*="drawing-tool"]', 'rs_skip_always');
                window.$rs.addClass('div[class=table-input]', 'rs_skip_always');
                window.$rs.addClass('div[class=text-entry]', 'rs_skip_always');
                window.$rs.addClass('div[class=ordered-list-question__container]', 'rs_skip_always');
                window.$rs.addClass('div[class=text-editor-content]', 'rs_skip_always');
                window.$rs.addClass('div[class=lesson-boolean-question__answers-wrapper]', 'rs_skip_always');
                window.$rs.addClass('div[class=video-provider]', 'rs_skip_always');
                window.$rs.addClass('div[class=ql-toolbar]', 'rs_skip_always');
                window.$rs.addClass('img[class=cloudinary-image]', 'rs_skip_always');
                window.$rs.addClass('[aria-label="Asset preview"]', 'rs_skip_always');
                window.$rs.addClass('input[aria-label="Select a class"]', 'rs_skip_always');
                window.$rs.addClass('input[aria-label="Select a student"]', 'rs_skip_always');
                window.$rs.addClass('img[class*=HeroImage]', 'rs_skip_always');
                window.$rs.addClass('span[class*=AccordionExpandIcon]', 'rs_skip_always');
                window.$rs.addClass('span[class*=Badgestyles__BadgeContainer]', 'rs_skip_always');
                window.$rs.addClass('button[aria-label="Expand Table"]', 'rs_skip_always');
                window.$rs.addClass('button[aria-label="Open menu"]', 'rs_skip_always');
                document.querySelectorAll('svg > title').forEach(element => element.classList.add('rs_skip_always'));
                window.$rs.addClass('span[class*="icon"]', 'rs_skip_always');
                window.$rs.addClass('button[aria-label="Scroll right"]', 'rs_skip_always');
                window.$rs.addClass('button[aria-label="Scroll left"]', 'rs_skip_always');
                window.$rs.addClass('button[aria-label="Close"]', 'rs_skip_always');
                window.$rs.addClass('[class*="TeacherControlBar"]', 'rs_skip_always');
                window.$rs.addClass('div[class*="Flyout"] > div[class*="SaveBar"]', 'rs_skip_always');
                window.$rs.addClass('span[class*="ql-formats"]', 'rs_skip_always');
            });
        }
    }, [window.ReadSpeaker, window.ReadSpeaker.Common]);

    return (
        <>
            <div className="rs_skip rsbtn rs_preserve">
                <a
                    className="rsbtn_play"
                    rel="nofollow"
                    title=""
                    href={
                        `${READSPEAKER_BUTTON_URL}?customerid=${READSPEAKER_CUSTOMER_ID}` +
                        `&lang=${getReadSpeakerLanguage(primaryLanguage)}&voice=${
                            primaryLanguage === 'es-US' ? READSPEAKER_SPANISH_VOICE : READSPEAKER_VOICE
                        }` +
                        `&readid=${elementId}&url=`
                    }
                >
                    <span className="rsbtn_left rsimg rspart">
                        <span className="rsbtn_text">
                            <Translation translationKey="general.read_speaker_listen" defaultValue="Listen" />
                        </span>
                    </span>
                    <span className="rsbtn_right rsimg rsplay rspart" />
                </a>
            </div>
        </>
    );
};

ReadSpeaker.defaultProps = {
    sessionId: '',
};

ReadSpeaker.propTypes = {
    elementId: PropTypes.string.isRequired,
    sessionId: PropTypes.string,
};

export default ReadSpeaker;
