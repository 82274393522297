import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import AccessErrorPage from '../AccessErrorPage';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import AsyncComponentError from '../../components/AsyncComponentError';

const TeacherDashboard = withProductSubscriptions(props => (
    <AsyncComponent
        appName="ms-teacher-dashboard-mfe"
        componentName="TeacherDashboard"
        errorComponent={AsyncComponentError}
        {...props}
    />
));

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];
const routes = [
    <Route
        path="/teacher-dashboard*"
        component={() => (
            <WithAuthentication
                roles={allowedRoles}
                RoleErrorComponent={() => <AccessErrorPage link="/student-dashboard/" />}
            >
                <TeacherDashboard Layout={Layout} />
            </WithAuthentication>
        )}
        key="teacher-dashboard"
    />,
];
export default routes;
