import { gql } from '@apollo/client';

export default gql`
    query productSubForProduct($userId: String!, $productCode: String!) {
        getUserProductSubscription(userId: $userId, productCode: $productCode) {
            productVariantCode
            languages {
                ietfTag
                purchased
            }
            tocsApp
            configuration {
                value
                key
            }
        }
    }
`;
