import { gql } from '@apollo/client';

const currentUserQuery = gql`
    query currentUser {
        currentUser {
            ... on TSTeacherUser {
                firstName
                lastName
                role
                id
            }
            ... on TSSchoolAdminUser {
                firstName
                lastName
                role
                id
            }
            ... on TSDistrictOwnerUser {
                firstName
                lastName
                role
                id
            }
            ... on TSStudentUser {
                firstName
                lastName
                role
                id
            }
            ... on TSUser {
                firstName
                lastName
                id
            }
        }
    }
`;

export default currentUserQuery;
