import store from '../../store/store';

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'x-product-variant-code': store.getState().subscriptions.product.productVariantCode,
    },
});

export default (operation, forward) => {
    const state = store.getState();
    const { subscriptions } = state;
    const hasProductVariantCode = subscriptions && subscriptions.product && subscriptions.product.productVariantCode;
    if (hasProductVariantCode) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
