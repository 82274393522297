export default function getUser(id = null, role = undefined, isStaff = false, testUser = false, districtId = null) {
    return {
        key: id === null || undefined ? 'defaultAnonymousUser' : id,
        anonymous: !role,
        custom: {
            isStaff,
            testUser,
            districtId,
            role: role || 'ANONYMOUS',
        },
    };
}
