import React from 'react';
import useIsLtiUser from '../components/Subscriptions/useIsLtiUser';

export default function withHideForLTIUser(Component) {
    function withHideForLTIUserComponent(props) {
        const isLtiUser = useIsLtiUser();

        return isLtiUser ? null : <Component {...props} />;
    }

    withHideForLTIUser.displayName = `withHideForLTIUser(${Component.displayName})`;
    return withHideForLTIUserComponent;
}
