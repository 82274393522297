import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { loginCallbackRoute, logoutCallbackRoute } from '@twigeducation/oidc-client-react';
import Dashboard from './Dashboard';
import NotFoundRoute from './pages/NotFound/Route';
import accountsRoutes from './pages/Accounts/routes';
import assetsRoutes from './pages/Assets/routes';
import teacherSessionRoutes from './pages/TeacherSession/routes';
import studentSessionRoutes from './pages/StudentSession/routes';
import lessonExplorerRoute from './pages/LessonExplorer/routes';
import teacherKnowledgeRoute from './pages/TeacherBackgroundKnowledge/routes';
import studentDashboardRoute from './pages/StudentDashboard/routes';
import teacherDashboardRoute from './pages/TeacherDashboard/routes';
import assignmentsRoutes from './pages/Assignments/routes';
import layoutFreeRoutes from './pages/LayoutFree/routes';
import favouritesRoutes from './pages/Favourites/routes';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';
import DigitalGlossaryRoute from './pages/DigitalGlossary/routes';
import CreditsRoutes from './pages/CreditsPage/routes';
import { LayoutRouteContent, LayoutRouteFooter, LayoutRouteWrapper } from './Routes.style';
import SkipToContent from './components/SkipToContent';
import useIsLtiUser from './components/Subscriptions/useIsLtiUser';

const Layout = () => (
    <LayoutRouteWrapper>
        <LayoutRouteContent id="main-content-skip">
            <Switch>
                <Route path="/" exact render={() => <Dashboard />} />
                {assignmentsRoutes}
                {loginCallbackRoute}
                {logoutCallbackRoute}
                {accountsRoutes}
                {assetsRoutes}
                {teacherSessionRoutes}
                {studentSessionRoutes}
                {lessonExplorerRoute}
                {teacherKnowledgeRoute}
                {studentDashboardRoute}
                {teacherDashboardRoute}
                {DigitalGlossaryRoute}
                {CreditsRoutes}
                {favouritesRoutes}
                {NotFoundRoute}
            </Switch>
        </LayoutRouteContent>
        <LayoutRouteFooter>
            <Footer />
        </LayoutRouteFooter>
    </LayoutRouteWrapper>
);

const Routes = () => {
    const isLtiUser = useIsLtiUser();

    return (
        <Switch>
            {layoutFreeRoutes}
            <Route
                render={() => (
                    <>
                        <ScrollToTop />
                        <SkipToContent target="#main-content-skip" />
                        {isLtiUser ? (
                            <Layout />
                        ) : (
                            <Navigation>
                                <Layout />
                            </Navigation>
                        )}
                    </>
                )}
            />
        </Switch>
    );
};

export default Routes;
