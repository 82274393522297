import React from 'react';
import PropTypes from 'prop-types';
import { Unleash } from '@twigeducation/unleash-client-react';
import { connect } from 'react-redux';
import getUser from './getUser';

const UnleashWrapper = ({ id, role, isStaff, testUser, districtId, children }) => {
    if (!role) {
        return children;
    }

    return (
        <Unleash
            url={window.config.UNLEASH_URL}
            clientId={window.config.UNLEASH_CLIENT_ID}
            user={getUser(id, role, isStaff, testUser, districtId)}
        >
            {children}
        </Unleash>
    );
};

UnleashWrapper.defaultProps = {
    id: 'genericAnonymousUser',
    role: undefined,
    isStaff: false,
    testUser: false,
    districtId: null,
};

UnleashWrapper.propTypes = {
    id: PropTypes.string,
    role: PropTypes.string,
    isStaff: PropTypes.bool,
    testUser: PropTypes.bool,
    districtId: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
};

const mapStateToProps = state => ({
    id: state.userInfo.id,
    role: state.userInfo.role,
    isStaff: state.userInfo.isStaff,
    testUser: state.userInfo.isTestUser,
    districtId: state.userInfo.districtId,
});

export default connect(mapStateToProps)(UnleashWrapper);
