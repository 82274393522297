import { useTranslation as useTranslationNext } from 'react-i18next';
import config from '../../../package.json';

const useTranslation = (ns = null, props = {}) => {
    const { t, ...rest } = useTranslationNext(ns, props);

    return {
        t: (translationKey, defaultValue, options) => {
            if (!translationKey) {
                return null;
            }
            return t(`${config.name}.${translationKey}`, defaultValue, options);
        },
        ...rest,
    };
};

export default useTranslation;
