import { Tracing } from '@twigeducation/honeycomb-browser';
import history from '../history';
import getUser from '../helpers/getUser';

const userInfo = getUser();

const tracing = new Tracing({
    buildCommitSha: window.config.IMAGE_GIT_COMMIT_SHA,
    buildVersion: window.config.IMAGE_VERSION,
    enabled: window.config.HONEYCOMB_ENABLED,
    history,
    honeycombDatasetName: 'ngss',
    honeycombWriteKey: '5c62bd7c288979eb890b5baf5ac04e6e',
    productName: window.config.PRODUCT_NAME,
    serviceName: 'tsc-react',
    resourcesToTrack: ['vendors.tsc-react', 'main.tsc-react'],
    userInfo,
});
tracing.addEventListeners();

export default tracing;
