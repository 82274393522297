import * as Sentry from '@sentry/react';
import { makeMultiplexedTransport } from '@sentry/core';
import { useSelector } from 'react-redux';
import history from '../history';

const DSN_MAPPINGS = {
    'ms-assessment-explorer-mfe': 'https://20ed8e295fec47d7abd110226ec240fd@o11481.ingest.sentry.io/4505209945128960',
    'ms-asset-preview-mfe': 'https://1d4722145a3b40e6b8a39fb9aa6095eb@o11481.ingest.sentry.io/4505209948864512',
    'ms-assign-share-mfe': 'https://e5f3132a87b543dd952058389df03f5d@o11481.ingest.sentry.io/4505209950306304',
    'ms-assignments-mfe': 'https://bcf476d5ee194b93a1c022fde1c2f98c@o11481.ingest.sentry.io/4505209951682560',
    'ms-breadcrumb-nav-mfe': 'https://18c110c414a043b29c740ca2d5c04f8f@o11481.ingest.sentry.io/4505209953255424',
    'ms-digital-glossary-mfe': 'https://8b3908be99e545829deb3e6d0379aee8@o11481.ingest.sentry.io/4505209954435072',
    'ms-favourites-mfe': 'https://ace58459010f4c1c95a66916df3aa9be@o11481.ingest.sentry.io/4505209955811328',
    'ms-footer-mfe': 'https://63660afa8b9743f5bf5149952cda91ed@o11481.ingest.sentry.io/4505209956990976',
    'ms-lesson-explorer-mfe': 'https://fb9487cd441d46e188d0c0b21dcc89c3@o11481.ingest.sentry.io/4505209958432768',
    'ms-navbar-mfe': 'https://b817a2324a2e45219d3fbf38948c48fd@o11481.ingest.sentry.io/4505209959546880',
    'ms-standards-mfe': 'https://51b36b5b66c742ec87277f04413f4226@o11481.ingest.sentry.io/4505209960923136',
    'ms-student-dashboard-mfe': 'https://43c582307ff549fb9b179d6a28a60b97@o11481.ingest.sentry.io/4505209962168320',
    'ms-student-session-mfe': 'https://58cc2524b6db4e54958bae4119d7edd5@o11481.ingest.sentry.io/4505209963282432',
    'ms-teacher-dashboard-mfe': 'https://e2ffb801f29545ae83136fb6de5c4a1f@o11481.ingest.sentry.io/4505209964527616',
    'ms-teacher-feedback-mfe': 'https://375737e03b924938a4ede9d0c7c8557e@o11481.ingest.sentry.io/4505209965903872',
    'ms-teacher-knowledge-mfe': 'https://0678b3c5042540f4b5e9fb3f29f30dce@o11481.ingest.sentry.io/4505209967083520',
    'ms-teacher-session-mfe': 'https://829b6f47f9ca4ef6831edb02339777a6@o11481.ingest.sentry.io/4505209968197632',
    'ts-accounts': 'https://f04813f27bbd4930bf0f7101e0467111@o11481.ingest.sentry.io/1423030',
    'ts-credits-mfe': 'https://586573cdf8344b2cb0c9b7e38ad65c78@o11481.ingest.sentry.io/4505209970491392',
    'twig-drawing-tool': 'https://ef5c7ac707d0478f9a22cdcc21e3a1bb@o11481.ingest.sentry.io/4505204279672832',
    'twig-pdf': 'https://83b7eeb2140b4ff4885b8d5e5db23821@o11481.ingest.sentry.io/4505204282294272',
    'twig-quill-mfe': 'https://0d95183b4dbe45c5aa8da9a2fdc45578@o11481.ingest.sentry.io/4505204286947328',
    'twig-video-mfe': 'https://271e538a72f04bc99ac5531dbb599d9e@o11481.ingest.sentry.io/4505204288192512',
};

function dsnFromFeature({ getEvent }) {
    const event = getEvent();
    const dsn = !DSN_MAPPINGS[event?.tags?.mfe];
    if (!dsn) {
        return [];
    }
    return dsn ? [dsn] : [];
}

export default function SentryWrapper({ children }) {
    const userInfo = useSelector(store => store.userInfo);
    const subscriptions = useSelector(store => store.subscriptions);
    const { tocsApp, productVariantCode } = subscriptions;

    if (userInfo) {
        Sentry.setUser({ id: userInfo.userId, ...userInfo });
        Sentry.setTags(userInfo);
    }

    if (subscriptions) {
        Sentry.setTags({ tocsApp, productVariantCode });
    }

    return children;
}

if (typeof window.config.SENTRY_PUBLIC_DSN === 'string' && window.config.SENTRY_PUBLIC_DSN !== '') {
    const tracePropagationTargets = ['/localhost/', '/ms.twigscience.com/', '/api.twigscience.com/', '/^//'];
    Sentry.init({
        dsn: window.config.SENTRY_PUBLIC_DSN,
        transport: makeMultiplexedTransport(Sentry.makeFetchTransport, dsnFromFeature),
        release: window.config.IMAGE_GIT_COMMIT_SHA,
        environment: window.config.SENTRY_ENVIRONMENT,
        attachStacktrace: true,
        normalizeDepth: 10,
        tracesSampleRate: 1,
        tracePropagationTargets,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets,
                // eslint-disable-next-line new-cap
                routingInstrumentation: new Sentry.reactRouterV5Instrumentation({ history }),
            }),
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /analytics\.js/i,
            /scripts\.hotjar\.com/i,
            /js-agent\.newrelic\.com/i,
            /acsbapp\.com/i,
            /g1980843350\.co/i,
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            /\?\(ga\)/i,
            /acsb/i,
            // Rapid page reflows triggers this. Doesn't affect the site.
            'ResizeObserver loop limit exceeded',
        ],
    });
}
