import { createStore, combineReducers } from 'redux';
import { reducer as authenticationReducer } from '@twigeducation/oidc-client-react';
import subscriptionsReducers from './reducers/subscriptions';
import { DEFAULT_LANGUAGE } from '../constants';
import featureFlagsReducer from './reducers/featureFlags';
import i18nReducer from './reducers/i18n';
import isLtiReducer from './reducers/isLti';

export const initialI18nState = {
    supportedLanguages: [
        {
            name: 'English',
            headerLabel: 'US English',
            code: 'en-US',
            translateKey: 'general.languages.english',
            shortName: 'EN',
        },
    ],
};

export const baseRequiredState = {
    userInfo: {},
    application: {
        id: '',
        defaultLanguage: DEFAULT_LANGUAGE,
    },
    subscriptions: {},
    featureFlags: {},
    i18n: initialI18nState,
    isLti: false,
};

export const configureStore = (initialState = {}) => {
    const appReducers = {
        userInfo: authenticationReducer,
        application: (state = {}) => state,
        subscriptions: subscriptionsReducers,
        featureFlags: featureFlagsReducer,
        i18n: i18nReducer,
        isLti: isLtiReducer,
    };

    const state = { ...baseRequiredState, ...initialState };
    const store = window.config.ENABLE_REDUX_DEV_TOOLS
        ? createStore(
              combineReducers(appReducers),
              state,
              // eslint-disable-next-line no-underscore-dangle
              window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
          )
        : createStore(combineReducers(appReducers), state);

    store.registeredReducers = { ...appReducers };

    store.registerNewReducersToStore = (name, newReducers) => {
        store.registeredReducers = {
            ...store.registeredReducers,
            [name]: newReducers,
        };

        store.replaceReducer(combineReducers({ ...store.registeredReducers, [name]: newReducers }));
    };

    return store;
};

export { configureStore as configureStoreForTest };
export default configureStore();
