import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withErrorBoundary } from '@twigeducation/allsorts';
import ReadSpeaker from './ReadSpeaker';
import ReadSpeakerBoundary, { displayRSErrorNotification } from './ReadSpeakerBoundary';
import useTranslation from '../../hooks/useTranslation';

const RSErrorHandler = () => {
    const { t } = useTranslation();
    useEffect(() => {
        displayRSErrorNotification(t);
    });

    return null;
};

export const handleReadSpeakerErrors = () => {
    const rsContainers = document.querySelectorAll('[class*=ReadSpeakerContainer]');
    rsContainers.forEach(container => {
        // eslint-disable-next-line no-param-reassign
        container.style.display = 'none';
    });

    const toastifyContainer = document.querySelector('div[class=Toastify]');
    if (toastifyContainer) {
        ReactDOM.render(<RSErrorHandler />, toastifyContainer);
    }
};

export default withErrorBoundary(<ReadSpeakerBoundary />, handleReadSpeakerErrors)(ReadSpeaker);
