import styled from 'styled-components';

export const VAlign = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    clear: both;
`;

export const ErrorMessage = styled.span`
    color: ${({ theme }) => theme.alertColor};
`;
