class App {
    manifests = [];

    registeredComponents = [];

    scriptLoadingCache = {};

    addManifest(appName, manifest) {
        this.manifests.push({
            appName,
            manifest,
        });
    }

    updateManifest(appName, manifest) {
        const existingManifestIndex = this.manifests.findIndex(man => man.appName === appName);
        if (existingManifestIndex === -1) {
            this.addManifest(appName, manifest);
            return;
        }
        this.manifests[existingManifestIndex].manifest = manifest;
    }

    registerComponent(componentName, ComponentToRegister) {
        this.registeredComponents.push({
            name: componentName,
            Component: ComponentToRegister,
        });
    }
}

export default App;
