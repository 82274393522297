const getUser = () => {
    const oidcUser = localStorage.getItem(
        `oidc.user:${window.config.OIDC_PROVIDER_URL}/:${window.config.SSO_CLIENT_ID}`,
    );

    if (oidcUser === null || oidcUser === undefined) {
        return {
            district_id: false,
            user_id: 'anonymousUser',
            is_staff: false,
            is_test_user: false,
            role: false,
        };
    }

    const userInfo = JSON.parse(oidcUser).profile.app_metadata;

    return {
        district_id: userInfo.district_id,
        user_id: userInfo.user_id,
        is_staff: userInfo.is_staff,
        is_test_user: userInfo.is_test_user,
        role: userInfo.role,
    };
};

export default getUser;
