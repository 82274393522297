import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../hooks/useTranslation';
import { updatePrimaryLanguage } from '../../store/actions/i18n';
import getUserPreferencesByUserIdQuery from './userPreferences.query';
import { gaSet } from '../../helpers/ga';

const I18n = ({ children }) => {
    const { i18n, ready } = useTranslation();
    const { userId, role } = useSelector(state => state?.userInfo);
    const supportedLanguages = useSelector(state => state?.i18n?.supportedLanguages?.map(lng => lng?.code));
    const internationalisationEnabled = useSelector(state => state?.featureFlags?.['ms-internationalisation']);
    const dispatch = useDispatch();

    const { data, loading, error } = useQuery(getUserPreferencesByUserIdQuery, {
        skip: !internationalisationEnabled || !userId || role !== 'STUDENT',
        variables: {
            userId,
        },
    });

    useEffect(() => {
        if (!internationalisationEnabled || !ready || loading || error || role !== 'STUDENT') {
            gaSet({ i18n_primaryLanguage: 'en-US' });
            return () => {};
        }

        const currentLanguage = i18n?.language;
        const language = data?.getUserPreferencesByUserId?.settings?.primary_language;

        if (language && language !== currentLanguage && supportedLanguages.includes(language)) {
            i18n.changeLanguage(language);
            document.documentElement.setAttribute('lang', language);
            dispatch(updatePrimaryLanguage(language));
            gaSet({ i18n_primaryLanguage: language });
        }
        return () => {};
    }, [internationalisationEnabled, role, data, loading, error, ready, dispatch]);

    if (loading) return null;

    return children;
};

I18n.propTypes = {
    children: PropTypes.node.isRequired,
};

export default I18n;
