import React, { useEffect, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useFeatureFlag } from '@twigeducation/unleash-client-react';
import { setProduct } from '../../store/actions/subscriptions';
import query from './Subscriptions.query';
import { setAvailableLanguages } from '../../store/actions/i18n/index';
import { gaSet } from '../../helpers/ga';
import { setIsLti } from '../../store/actions/isLti/isLtiActions';

const getAvailableLanguage = languages => {
    if (!languages.length) {
        return [
            {
                name: 'English',
                headerLabel: 'US English',
                code: 'en-US',
                translateKey: 'general.languages.english',
                shortName: 'EN',
            },
        ];
    }

    const availableLanguages = [
        // only codes come from BE, but this should all eventually be defined there
        {
            name: 'English',
            headerLabel: 'US English',
            code: 'en-US',
            translateKey: 'general.languages.english',
            shortName: 'EN',
        },
        {
            name: 'Spanish',
            headerLabel: 'Español',
            code: 'es-US',
            translateKey: 'general.languages.spanish',
            shortName: 'ES',
        },
    ];

    const subscribedLanguageCodes = languages.filter(lang => lang.purchased === true).map(lang => lang.ietfTag);
    const subscribedLanguages = availableLanguages.filter(availableLang =>
        subscribedLanguageCodes.includes(availableLang.code),
    );

    return subscribedLanguages;
};

const Subscriptions = ({ store, children }) => {
    const state = store.getState();
    const { userInfo } = state;
    const ltiLaunchFlag = useFeatureFlag('ms_rtk_lti_launch');

    if (!userInfo) {
        return null;
    }

    const { data, error } = useQuery(query, {
        skip: !userInfo?.id,
        variables: {
            userId: userInfo.id,
            productCode: 'TSMS',
        },
    });

    useLayoutEffect(() => {
        store.dispatch(
            setIsLti({
                flagEnabled: ltiLaunchFlag,
                isLtiDeployment: window.config.IS_LTI,
                configurations: data?.getUserProductSubscription?.configuration || [],
            }),
        );
    }, [store, ltiLaunchFlag, data]);

    useEffect(() => {
        if (data) {
            const { productVariantCode, tocsApp, configuration, languages } = data?.getUserProductSubscription ?? {};
            store.dispatch(setAvailableLanguages(getAvailableLanguage(languages)));
            store.dispatch(setProduct({ productVariantCode, tocsApp, configuration }));
            gaSet({ tocsApp, productVariantCode });
        }
    }, [data]);

    return (
        <>
            {error && <div style={{ visibility: 'hidden', height: '0px', width: '0px' }}>{error.message}</div>}
            {children}
        </>
    );
};

Subscriptions.propTypes = {
    store: PropTypes.shape({
        dispatch: PropTypes.func.isRequired,
        getState: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Subscriptions;
