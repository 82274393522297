import React from 'react';
import propTypes from 'prop-types';
import { PageHeadAttributes } from '@twigeducation/allsorts';
import useTranslation from '../../hooks/useTranslation';
import PageError from './NotSubscribedError.styled';

const NotSubscribedError = ({ link }) => {
    const { t } = useTranslation();
    return (
        <>
            <PageHeadAttributes title={t('not_subscribed_error_page.page_title', 'Not Subscribed')} separator="pipe" />
            <PageError
                title={t('not_subscribed_error_page.title', 'Uh oh, you look a bit lost!')}
                body={t(
                    'not_subscribed_error_page.message',
                    `You don't have access to this product. 
                If you have multiple accounts, please check you are logged in with the correct account.`,
                )}
                buttonConfig={{
                    label: t('not_subscribed_error_page.button_label', 'Go to my Dashboard'),
                    onClick: () => {
                        window.location.href = link;
                    },
                }}
            />
        </>
    );
};

NotSubscribedError.propTypes = {
    link: propTypes.string,
};

NotSubscribedError.defaultProps = {
    link: 'https://app.twigscience.com/',
};

export default NotSubscribedError;
