import { createBrowserHistory } from 'history';

// Set the history basename if accessing a review app
const pathname = window.location.pathname.split('/');
const isReviewApp = pathname.includes('rvw') && pathname.length >= 4;
const basename = pathname.slice(0, pathname.indexOf('rvw') + 2).join('/');
const history = isReviewApp ? createBrowserHistory({ basename }) : createBrowserHistory();

export { basename };
export default history;
