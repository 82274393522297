export const actions = {
    SET_PRODUCT: 'SET_PRODUCT',
};

const setProduct = product => ({
    type: actions.SET_PRODUCT,
    value: product,
});

export { setProduct };
