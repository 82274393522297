import { gql, useMutation } from '@apollo/client';

export const upsertUserPreferencesByUserId = gql`
    mutation upsertUserPreferencesByUserId($input: UserPreferencesByUserIdInput!) {
        upsertUserPreferencesByUserId(input: $input) {
            id
            settings
        }
    }
`;

const useUpsertUserPreferencesByUserId = () => useMutation(upsertUserPreferencesByUserId);

export default useUpsertUserPreferencesByUserId;
