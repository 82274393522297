import React from 'react';
import PropTypes from 'prop-types';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';

const LogoutButton = ({ onLogout }) => (
    <button onClick={onLogout} type="button">
        Logout
    </button>
);

LogoutButton.propTypes = {
    onLogout: PropTypes.func.isRequired,
};

export default withSignoutRedirect(() => {})(LogoutButton);
