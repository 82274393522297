import { SET_AVAILABLE_LANGUAGES, UPDATE_PRIMARY_LANGUAGE } from './actionTypes';

const setAvailableLanguages = languages => ({
    type: SET_AVAILABLE_LANGUAGES,
    value: languages,
});

const updatePrimaryLanguage = primaryLanguage => ({
    type: UPDATE_PRIMARY_LANGUAGE,
    value: primaryLanguage,
});

export { setAvailableLanguages, updatePrimaryLanguage };
