import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/react';
import { Warning } from '@twigeducation/ts-fe-components/components/Icons';
import useTranslation from '../../hooks/useTranslation';
import { ErrorMessage, VAlign } from './AsyncComponentError.styles';

export default function AsyncComponentError({ error }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (error) {
            captureException(error);
        }
    }, [error]);

    return (
        <VAlign>
            <Warning size={24} color="#D0021B" />
            <ErrorMessage>{t('errors.async_component_error', 'Error loading component')}</ErrorMessage>
        </VAlign>
    );
}

AsyncComponentError.propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
};
