import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FeatureFlag } from '@twigeducation/unleash-client-react';
import { RedirectToLogin } from '@twigeducation/oidc-client-react';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { TEACHER, STUDENT, SCHOOL_ADMIN, DISTRICT_OWNER } from './constants';
import LogoutButton from './LogoutButton';
import teacherDashboardUrl from './pages/TeacherDashboard/urls';
import studentDashboardUrl from './pages/StudentDashboard/urls';
import currentUserQuery from './CurrentUserQuery.query';

const Dashboard = ({ userInfo }) => {
    if (!userInfo.id) return <RedirectToLogin />;
    const schoolAdminUrl = '/lesson-explorer';
    const redirectUrl = {
        [TEACHER]: teacherDashboardUrl,
        [STUDENT]: studentDashboardUrl,
        [SCHOOL_ADMIN]: schoolAdminUrl,
    }[userInfo.role];
    const { loading, error, data } = useQuery(currentUserQuery);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{`Error! ${error.message}`}</p>;
    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    }
    const externalRedirect = {
        [DISTRICT_OWNER]: 'https://admin.twigscience.com',
    }[userInfo.role];
    if (externalRedirect) {
        window.location.href = externalRedirect;
        return <LoadingPlaceholder />;
    }
    return (
        <FeatureFlag
            key="middle-school-test"
            flagKey="middle-school-test"
            renderFeatureCallback={() => (
                <div>
                    <p>Logged in as {data.currentUser.firstName}</p>
                    <p>
                        <Link to="/accounts/profile">View profile</Link>
                    </p>
                    <LogoutButton />
                </div>
            )}
            renderDefaultCallback={() => null}
        />
    );
};

Dashboard.propTypes = {
    userInfo: PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = state => ({ userInfo: state.userInfo });

export default connect(mapStateToProps)(Dashboard);
export { currentUserQuery };
