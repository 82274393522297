import { actions } from '../../actions/isLti/isLtiActions';

const { SET_IS_LTI } = actions;

export default (state = false, action) => {
    switch (action.type) {
        case SET_IS_LTI:
            if ([undefined, true].includes(action.payload.flagEnabled)) {
                return action.payload.isLtiDeployment;
            }
            return (
                action.payload.configurations?.some(
                    config =>
                        config.__typename === 'ProductConfiguration' &&
                        config.value === 'LTI' &&
                        config.key === 'integrationType',
                ) || false
            );

        default:
            return state;
    }
};
