import React from 'react';
import PropTypes from 'prop-types';

const Layout = ({ component: Component, ...props }) => <Component {...props} />;

Layout.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default Layout;
